import tw from 'twin.macro';

const Section = tw.section`
  flex flex-col
  w-full md:max-w-[1100px]
  text-color-ivory
  px-6 pt-[6.25rem] pb-[3.75rem]
  md:px-0 md:py-[8.75rem]
  md:mx-auto
`;

export default Section;
