import { FC } from 'react';
import tw from 'twin.macro';

import { Faq } from 'src/types';
import useFadeIn from 'src/hooks/useFadeIn';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

interface Props {
  faq: Faq;
}

const Base = styled(motion.li)`
  ${tw`
    space-y-3.5
  `}
`;

const Question = tw.p`
  flex flex-row items-start
  text-base font-bold md:text-xl
  text-color-gray09
  opacity-90
`;

const Answer = tw.div`
  inline-flex items-center
  space-x-2 md:space-x-4
  w-auto h-full
`;

const Divider = tw.div`
  w-1 h-10 md:h-11
  min-width[0.25rem]
  bg-color-gray02
  rounded-3xl
`;

const AnswerText = tw.p`
  text-sm md:text-lg font-light
  text-color-gray05
  opacity-90
`;

const FaqItem: FC<Props> = ({ faq }) => {
  if (!faq) {
    return null;
  }

  const { question, answer } = faq;

  const props1 = useFadeIn();

  return (
    <Base {...props1}>
      <Question>Q.&nbsp;{question}</Question>
      <Answer>
        <Divider />
        <AnswerText>{answer}</AnswerText>
      </Answer>
    </Base>
  );
};

export default FaqItem;
