/**
 * Reference https://gist.github.com/reecelucas/2f510e6b8504008deaaa52732202d2da
 */

import {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

interface ScrollBlock {
  scrollBlocked: MutableRefObject<boolean>;
  blockScroll: () => void;
  allowScroll: () => void;
}

export const scrollBlockContext = createContext<ScrollBlock>(null);

export const useProvideScrollBlock = () => {
  const scrollBlocked = useRef<boolean>(false);

  if (typeof document === 'undefined') {
    return {
      scrollBlocked,
      blockScroll: () => {},
      allowScroll: () => {},
    };
  }

  const { documentElement: html, body } = useMemo(
    () => document || ({} as Document),
    [document]
  );

  const isNotDocumentReady = useMemo(
    () => !html || !html.style || !body || !body.style,
    [html, body]
  );

  const blockScroll = useCallback(() => {
    if (isNotDocumentReady || scrollBlocked.current) {
      return;
    }

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue('padding-right')
      ) || 0;

    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
    body.style.overflowY = 'hidden';

    scrollBlocked.current = true;
  }, [isNotDocumentReady, html, body]);

  const allowScroll = useCallback(() => {
    if (isNotDocumentReady || !scrollBlocked.current) {
      return;
    }

    body.style.paddingRight = '';
    body.style.overflowY = '';

    scrollBlocked.current = false;
  }, [isNotDocumentReady, html, body]);

  return {
    scrollBlocked,
    blockScroll,
    allowScroll,
  };
};

const useScrollBlock = () => useContext(scrollBlockContext);

export default useScrollBlock;
