import tw from 'twin.macro';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import useFadeIn from 'src/hooks/useFadeIn';

const Base = styled(motion.div)`
  ${tw`
    w-full
    flex flex-row flex-wrap
    md:flex-nowrap md:justify-between
    -mb-3 md:mb-0 md:pb-5
  `}
`;

const Item = tw.img`
  fill-[#ffffff]
  h-[20px] md:h-[32px]
  mr-4 md:mr-0
  mb-3 md:mb-0
  last-of-type:mr-0
`;

function LogoList() {
  const props1 = useFadeIn();

  return (
    <Base {...props1}>
      <Item src="/logos/img-ci-hyperconnect.svg" alt="hyperconnect" />
      <Item src="/logos/img-ci-matchgroup.svg" alt="match group" />
      <Item src="/logos/img-ci-azar.svg" alt="azar" />
      <Item src="/logos/img-ci-hakuna.svg" alt="hakuna" />
      <Item src="/logos/img-ci-slide.svg" alt="slide" />
      <Item src="/logos/img-ci-enterprise.svg" alt="hyperconnect enterprise" />
    </Base>
  );
}

export default LogoList;
