import { Children, ReactNode } from 'react';
import tw from 'twin.macro';

import { BASE_URL, FOOTER_BUTTON_LINKS } from 'src/constants';
import SvgLogo from 'src/images/specialPage/logo.svg';

import SvgFamilyLogo from 'src/images/img-mg-family-logo.svg';
import SNSIcon from 'src/components/SNSIcon';

const Base = tw.footer`
  flex flex-col items-center
  w-full
  bg-color-darkgray09
  py-[4.5rem] md:py-[3.75rem]
`;

const Section = tw.div`
  text-color-ivory
  w-full max-w-[calc(1100px + 24px * 2)]
  px-[1.5rem]
  flex flex-col
`;

const Top = tw(Section)`
  flex flex-col md:flex-row md:items-center
`;

const Mid = tw(Section)`
  py-[2rem] md:py-[3.75rem]
  grid grid-flow-row auto-rows-min gap-y-2
`;

const Bottom = tw(Section)`
  pt-[1.87rem] md:pt-[1.625rem]
`;

const TextSection = tw.div`
  flex flex-row items-center gap-1
`;

const FooterTitle = tw.h6`
  font-sans font-light
  text-sm
`;

const FooterLink = tw.a`
  font-sans font-light
  text-sm text-color-gray05
  no-underline
  hover:text-color-primary hover:underline
  active:text-color-primary active:underline
`;

const CopyrightText = tw.p`
  font-sans font-light
  text-sm
  text-color-gray05
  m-0
`;

const ButtonGroup = tw.div`
  grid grid-flow-col auto-cols-min gap-x-2
`;

const FooterLogo = tw(SvgLogo)`
  w-[169.41px] h-full
  fill-[#ffffff]
`;

const FamilyLogo = tw(SvgFamilyLogo)`
  h-[18px] md:h-full
  mt-2 ml-[-2px] md:mt-0 md:ml-2
`;

interface Props {
  className?: string;
}

function SpecialFooter({ className }: Props): JSX.Element {
  return (
    <Base className={className}>
      <Top>
        <FooterLogo />
        <a href="https://mtch.com" target="_blank" rel="noreferrer"><FamilyLogo /></a>
      </Top>
      <Mid>
        <TextSection>
          <FooterTitle>채용 사이트</FooterTitle>
          <FooterLink href={BASE_URL} target="_blank" rel="noreferrer">
            {BASE_URL}
          </FooterLink>
        </TextSection>
        <TextSection>
          <FooterTitle>채용 문의</FooterTitle>
          <FooterLink href="mailto:career@hpcnt.com">
            career@hpcnt.com
          </FooterLink>
        </TextSection>
        <CopyrightText>&copy; {new Date().getFullYear()} HYPERCONNECT LLC. All rights reserved</CopyrightText>
      </Mid>
      <Bottom>
        <ButtonGroup>
          {Children.toArray(
            FOOTER_BUTTON_LINKS.map(
              ({ link, icon: Icon, hovered: Hovered }) => (
                <SNSIcon
                  link={link}
                  icon={<Icon tw="w-8 h-8" />}
                  hovered={<Hovered tw="w-8 h-8" />}
                />
              )
            )
          )}
        </ButtonGroup>
      </Bottom>
    </Base>
  );
}

export default SpecialFooter;
