import { Ref, useEffect, useRef, useState } from 'react';

function useHover<Element extends HTMLElement>(): [Ref<Element>, boolean] {
  const ref = useRef<Element>(null);
  const [isHover, setHover] = useState(false);

  useEffect(() => {
    const handleMouseOver = () => setHover(true);
    const handleMouseOut = () => setHover(false);

    const node = ref.current;

    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);

  return [ref, isHover];
}

export default useHover;
