import styled from '@emotion/styled';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import tw from 'twin.macro';

import useBreakPoint from 'src/hooks/useBreakPoint';

interface Props {
  frontFace: ReactNode;
  backFace: ReactNode;
  disabled: boolean;
}

/**
 *  Reference https://www.w3schools.com/howto/howto_css_flip_card.asp
 */
const Base = styled.div<{ isFlip: boolean; disabled: boolean }>`
  ${tw`
    w-full h-full
    bg-transparent
    perspective[1000px]
  `}

  ${({ isFlip }) =>
    isFlip &&
    tw`
    not-disabled:all-child:transform[rotateY(180deg)]
  `}

  ${({ disabled }) =>
    !disabled && tw`md:hover:all-child:transform[rotateY(180deg)]`}
`;

const FlipCardInner = tw.div`
  relative
  w-full h-full
  transition[transform 0.8s]
  md:hover:transition-delay[0.5s]
  transform-style[preserve-3d]
`;

const FlipCardFace = tw.div`
  absolute
  w-full h-full
  -moz-backface-visibility[hidden]
  -webkit-backface-visibility[hidden]
  backface-visibility[hidden]
`;

const FlipCardBack = tw(FlipCardFace)`
  transform[rotateY(180deg)]
`;

const FlipCard: FC<Props> = ({ frontFace, backFace, disabled }) => {
  const md = useBreakPoint('md');

  const [isFlip, setIsFlip] = useState(false);

  const handleClick = useCallback(() => {
    if (!md && !disabled) {
      setIsFlip(prev => !prev);
    }
  }, [md, disabled]);

  useEffect(() => {
    if (disabled) {
      setIsFlip(false);
    }
  }, [disabled]);

  return (
    <Base isFlip={isFlip} disabled={disabled} onClick={handleClick}>
      <FlipCardInner>
        <FlipCardFace>{frontFace}</FlipCardFace>
        <FlipCardBack>{backFace}</FlipCardBack>
      </FlipCardInner>
    </Base>
  );
};

export default FlipCard;
