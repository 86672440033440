import React from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from '@emotion/styled';
import { useLocation } from '@reach/router';

import Section from 'src/components/common/Section';
import AutoplayVideo from 'src/components/AutoplayVideo';
import { BASE_URL, RECOMMEND_URL, SHARE_BUTTON_LINKS } from 'src/constants';
import Mp4Background from 'src/videos/specialPage/biz-background-top.mp4';
import WebMBackground from 'src/videos/specialPage/biz-background-top.webm';

import DDay from './dday';
import SNSIcon from 'src/components/SNSIcon';
import { scrollTo } from 'src/utils/scroll';
import useFadeIn from 'src/hooks/useFadeIn';

const Base = tw.section`
  relative
  w-full
  z-0
`;

const Wrapper = styled(motion.div)`
  ${tw`
    flex flex-col items-center
    w-full max-w-[782px]
  `}
`;

const BackgroundVideo = styled(AutoplayVideo)`
  ${tw`
    absolute inset-0 z-[-1]
    w-full h-full
    object-center object-cover
  `}
`;

const BackgroundOverlay = tw.div`
  absolute inset-0 bg-black/80 z-[-1]
  mr-[-1px]
`;

const Title = styled(motion.h2)`
  ${tw`
    font-poppins italic
    font-weight[600]
    text-color-primary
    text-[32px] md:text-[72px]
    text-center
    mb-[14px] md:mb-[50px]
  `}
`;

const Description = tw.p`
  font-sans font-weight[400]
  text-color-gray09 opacity-80
  text-[20px] md:text-[32px]
  text-center
  m-0 mb-[24px]
  select-none
`;

const ApplyButton = tw.button`
  font-sans font-weight[700]
  text-[16px] md:text-[24px]
  text-center
  rounded-full
  outline-none
  px-[32px] py-[16px] md:px-[42px] md:py-[20px]
  bg-color-primary text-color-gray01
  hover:(bg-color-primary-deep)
  active:(bg-color-primary-deep)
`;

const CardBase = styled(motion.article)`
  ${tw`
    bg-[#222222e6]
    w-full
    flex flex-col items-center
    mt-[3.75rem] md:mt-[6.25rem]
    mb-[1.25rem] md:mb-0
    py-[2.5rem]
    rounded-[1.5rem]
  `}
`;

const CardSubtitle = tw.p`
  font-sans font-weight[400]
  text-color-primary-deep
  text-sm md:text-[1.5rem]
  mb-[2rem]
`;

const CardTitle = tw.h4`
  font-sans font-weight[700]
  text-center
  text-color-gray09
  text-[1.25rem] md:text-[2.5rem]
`;

const RewardButton = tw.a`
  mt-[1.25rem]
  px-[1.25rem] md:px-[2.625rem] py-[1rem] md:py-[1.25rem]
  border-[1px] border-white
  hover:(bg-white text-color-gray01)
  active:(bg-white text-color-gray01)
  rounded-full
  font-sans font-weight[700]
  text-base md:text-[1.25rem]
  text-white
`;

const SNSButtonContainer = tw.div`
  my-[1.25rem]
  grid grid-flow-col auto-cols-auto gap-x-3
`;

function SpecialDDaySection() {
  const props1 = useFadeIn();
  const props2 = useFadeIn();
  const props3 = useFadeIn();

  const location = useLocation();

  return (
    <Base>
      <BackgroundVideo
        srcSet={[
          { src: Mp4Background, videoType: 'video/mp4' },
          { src: WebMBackground, videoType: 'video/webm' },
        ]}
        poster="/special/biz-background-top-poster.jpg"
      />
      <BackgroundOverlay />
      <Section tw="w-full flex-col items-center pb-10 bg-transparent">
        <Title {...props1}>Be a Hyperconnector!</Title>
        <Wrapper {...props2}>
          <Description>Special Benefit 집중 채용 기간</Description>
          <DDay tw="w-full mb-[32px]" />
          {/* <ApplyButton type="button" onClick={() => scrollTo('jobs')}>
            Business 상시채용 더 알아보기 
          </ApplyButton> */}
        </Wrapper>
        {/* <CardBase {...props3}>
          <CardSubtitle>
            {'2022 하이퍼커넥트 릴레이 채용 Business편'}
          </CardSubtitle>
          <CardTitle>
            {'지인에게 이 소식을 알려주고'}
            <br />
            {'200만원의 보상금을 받으세요!'}
          </CardTitle>
          <SNSButtonContainer>
            {React.Children.toArray(
              SHARE_BUTTON_LINKS.map(
                ({ link, icon: Icon, hovered: Hovered }) => (
                  <SNSIcon
                    link={link(`${BASE_URL}${location.pathname}`)}
                    icon={<Icon tw="w-8 h-8" />}
                    hovered={<Hovered tw="w-8 h-8" />}
                  />
                )
              )
            )}
          </SNSButtonContainer>
          <RewardButton href={RECOMMEND_URL} target="_blank" rel="noreferrer">
            지인 추천하고 보상금 받기
          </RewardButton>
        </CardBase> */}
      </Section>
    </Base>
  );
}

export default SpecialDDaySection;
