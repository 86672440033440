import { Children, FC } from 'react';
import tw from 'twin.macro';
import { motion } from 'framer-motion';

import Section from 'src/components/common/Section';
import SectionTitle from 'src/components/common/Section/Title';
import { HIRING_PROCESS_LIST } from 'src/constants/2022biz';
import useFadeIn from 'src/hooks/useFadeIn';

const Body = tw(motion.div)`
  flex flex-col
  divide-y divide-color-gray02
  border-t border-b border-color-gray02
`;

const Article = tw.article`
  flex flex-col
  md:grid md:grid-cols-10
  text-left
  space-y-1.5 md:space-y-0
  py-3.5 md:py-6
`;

const Title = tw.p`
  md:col-span-2
  text-base md:text-2xl
  text-color-primary font-bold
  opacity-90
`;

const Content = tw.p`
  md:col-span-8
  text-sm md:text-2xl
  text-color-gray05 font-light
  md:word-break[keep-all]
  opacity-90
`;

const TITLE = 'Hiring Process';

const SUB_TITLE = '하이퍼커넥트 합류 여정';

const HiringProcess: FC = () => {
  const props1 = useFadeIn();

  return (
    <Section>
      <SectionTitle title={TITLE} subTitle={SUB_TITLE} />
      <Body {...props1}>
        {Children.toArray(
          HIRING_PROCESS_LIST.map(({ title, content }) => (
            <Article>
              <Title>{title}</Title>
              <Content>{content}</Content>
            </Article>
          ))
        )}
      </Body>
    </Section>
  );
};

export default HiringProcess;
