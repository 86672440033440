import React, { FC, HTMLAttributes } from 'react';
import tw from 'twin.macro';

import BaseSection from 'src/components/common/Section';
import SectionTitle from 'src/components/common/Section/Title';

import JobList from './JobList';
import Caveats from '../Caveats';

const TITLE = 'HELLO, IS IT YOU\nWE’RE LOOKING FOR?';

const Wrapper = tw.div`
  relative w-screen
  bg-color-gray01
`;

const Section = tw(BaseSection)`
  pb-5 md:pb-9
`;

type Props = Pick<HTMLAttributes<HTMLDivElement>, 'id' | 'className'>;

const JDList: FC<Props> = props => (
  <Wrapper {...props}>
    <Section>
      <SectionTitle title={TITLE} />
      <JobList />
      <Caveats />
    </Section>
  </Wrapper>
);

export default JDList;
