import { FC } from 'react';
import tw from 'twin.macro';

interface Props {
  size?: number;
  color?: string;
}

const SvgIcon = tw.svg`
  inline-block align-middle
`;

const CloseIcon: FC<Props> = ({ size = 24, color = 'white' }) => (
  <SvgIcon
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.637 4.364a.9.9 0 0 1 0 1.272l-14 14a.9.9 0 1 1-1.273-1.272l14-14a.9.9 0 0 1 1.273 0Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.364 4.364a.9.9 0 0 1 1.272 0l14 14a.9.9 0 1 1-1.272 1.272l-14-14a.9.9 0 0 1 0-1.272Z"
      fill={color}
    />
  </SvgIcon>
);

export default CloseIcon;
