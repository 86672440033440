import { FC } from 'react';
import tw from 'twin.macro';

import BaseSection from 'src/components/common/Section';
import BaseSectionTitle from 'src/components/common/Section/Title';

import CardList from './CardList';

const Section = tw(BaseSection)`
  px-0
`;

const SectionTitle = tw(BaseSectionTitle)`
  px-6 md:px-0
`;

const TITLE = 'We’re looking for';

const LookingFor: FC = () => (
  <Section>
    <SectionTitle title={TITLE} />
    <CardList />
  </Section>
);

export default LookingFor;
