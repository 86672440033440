import React from 'react';
import tw from 'twin.macro';

import GlobalStyles from 'src/styles/GlobalStyles';
import SEO from 'src/components/SEO';
import Footer from 'src/components/RecruitLandingPage/2022Biz/SpecialFooter';

import SpecialTopSection from 'src/components/RecruitLandingPage/2022Biz/SpecialTop';
import LookingFor from 'src/components/RecruitLandingPage/2022Biz/LookingFor';
import SpecialBenefit from 'src/components/RecruitLandingPage/2022Biz/SpecialBenefit';
import HiringProcess from 'src/components/RecruitLandingPage/2022Biz/HiringProcess';
import HyperPlay from 'src/components/RecruitLandingPage/2022Biz/HyperPlay';
import FAQ from 'src/components/RecruitLandingPage/2022Biz/FAQ';
import JDList from 'src/components/RecruitLandingPage/2022Biz/JDList';
import DDay from 'src/components/RecruitLandingPage/2022Biz/SpecialDDay';
import SpecialCarouselSection from 'src/components/RecruitLandingPage/2022Biz/SpecialCarousel';
import {
  scrollBlockContext,
  useProvideScrollBlock,
} from 'src/hooks/useScrollBlock';
import SpecialHeader from 'src/components/RecruitLandingPage/2022Biz/SpecialHeader';
import SpecialPopup from 'src/components/RecruitLandingPage/2022Biz/SpecialPopup';

const Base = tw.article`
  w-screen max-w-[100vw]
  min-h-screen
  cursor[url('/special/cursor.svg') 16 16, default]
  overflow-x-hidden
`;

function SpecialPage(): JSX.Element {
  const scrollBlock = useProvideScrollBlock();

  return (
    <scrollBlockContext.Provider value={scrollBlock}>
      <Base>
        <GlobalStyles />
        <SpecialHeader tw="max-w-[100vw]" />
        <SpecialTopSection />
        <LookingFor />
        <HyperPlay />
        <SpecialBenefit />
        <HiringProcess />
        <SpecialCarouselSection />
        <FAQ />
        <DDay />
        <JDList id="jobs" />
        <Footer tw="mt-auto" />
        <SpecialPopup opened/>
      </Base>
    </scrollBlockContext.Provider>
  );
}

export default SpecialPage;


export const Head = () => {
  return (
    <SEO
      title={'2022 하이퍼커넥트 릴레이 채용 Business편'}
      description={
        'Special Benefit이 있는 ‘2022 하이퍼커넥트 릴레이 채용’! 한국에서 가장 글로벌한 마케팅을 경험할 수 있는 ‘Business편’을 시작합니다! 500만 원의 글로벌 역량 강화 지원금을 받고 입사할 수 있는 절호의 기회! 하이퍼커넥트에서 글로벌 커리어 여정을 시작하세요!'
      }
      image={'/seo-cover-recruit2022-biz.png'}
    />
  )
}