import { Children, FC } from 'react';
import tw from 'twin.macro';

import Section from 'src/components/common/Section';
import SectionTitle from 'src/components/common/Section/Title';
import { FAQ_LIST } from 'src/constants/2022biz';

import FaqItem from './FaqItem';
import useFadeIn from 'src/hooks/useFadeIn';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const Body = tw.div``;

const FaqList = tw.ul`
  space-y-6 md:space-y-10
  list-none
  mb-6 md:mb-[3.75rem]
`;

const AdditionalInfo = styled(motion.div)`
  ${tw`
    inline-flex flex-none items-start
    w-full
    space-x-1.5
    background-color[rgba(170, 170, 170, 0.1)]
    rounded-lg
    px-4 py-2 md:p-3
  `}
`;

const InfoText = tw.p`
  text-xs leading-normal md:text-sm
  text-color-gray06
  font-extralight md:font-light
  opacity-90
`;

const Email = tw.a`
  text-color-primary md:font-extralight
`;

const InfoIcon = tw.img`
  w-4 h-4
  md:w-[1.125rem] md:h-[1.125rem]
`;

const TITLE = 'FAQ';

function Contact() {
  const props1 = useFadeIn();

  return (
    <AdditionalInfo {...props1}>
      <InfoIcon src="/icons/ic-info.svg" />
      <InfoText>
        찾으시는 질문이 없을 경우 하이퍼커넥트 채용팀{' '}
        <Email href="mailto:career@hpcnt.com">career@hpcnt.com</Email>
        으로 문의해주시길 바랍니다.
      </InfoText>
    </AdditionalInfo>
  );
}

const FAQ: FC = () => (
  <Section>
    <SectionTitle title={TITLE} />
    <Body>
      <FaqList>
        {Children.toArray(FAQ_LIST.map(faq => <FaqItem faq={faq} />))}
      </FaqList>
      <Contact />
    </Body>
  </Section>
);

export default FAQ;
