import styled from '@emotion/styled';
import {
  FC,
  MouseEvent,
  TouchEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import throttle from 'lodash.throttle';

import { CARD_LIST } from 'src/constants/2022biz';
import useBreakpoint from 'src/hooks/useBreakPoint';
import useFadeIn from 'src/hooks/useFadeIn';
import useScrollBlock from 'src/hooks/useScrollBlock';

import Card from './Card';

const CardSlider = styled(motion.div)`
  ${tw`
    w-screen
    overflow-x-hidden md:overflow-x-visible
    select-none

    // get bottom area for card flip animation
    py-10 md:py-14
    -my-10 md:-my-14
  `}
`;

const CardDragger = styled.div`
  ${tw`
    flex flex-row items-end
    w-max h-[200px] md:h-[400px]
    space-x-3.5 md:space-x-6
    px-6 md:px-0

    transition[transform 0.4s ease]
    -webkit-user-drag[none]
  `}
`;

const CARDS_LENGTH = 4;

const CardList: FC = () => {
  const md = useBreakpoint('md');
  const { allowScroll, blockScroll, scrollBlocked } = useScrollBlock();

  const ref = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef<boolean>(false);
  const elementXRef = useRef<number>(0);

  const [transformX, setTransformX] = useState(0);
  const [currentCard, setCurrentCard] = useState(0);

  const props1 = useFadeIn();

  const { cardWidth, maximumTransformX } = useMemo(() => {
    // space-x value (desktop: 24, mobile: 14) of card list
    const spaceX = md ? 24 : 14;
    const cardWidth = (md ? 500 : 250) + spaceX;

    // maximumTransformX is just whole cards width except current card
    const maximumTransformX = cardWidth * (CARDS_LENGTH - 1);

    return {
      cardWidth,
      maximumTransformX,
    };
  }, [md]);

  const isNextAble = currentCard * cardWidth < maximumTransformX;
  const isPrevAble = currentCard > 0;

  const handleTransform = useCallback(
    (position: number) => {
      const positionX = Math.min(position * cardWidth, maximumTransformX);
      ref.current.style.transform = `translate(${-positionX}px, 0px)`;
      setTransformX(-positionX);
    },
    [cardWidth, maximumTransformX]
  );

  const handleTouchStart = (e: TouchEvent) => {
    ref.current.style.transition = 'transform 0.1s ease';
    isDraggingRef.current = true;
    elementXRef.current = e.changedTouches[0].clientX - transformX;
  };

  const handleDragStart = (e: MouseEvent) => {
    ref.current.style.transition = 'transform 0.05s ease';
    isDraggingRef.current = true;
    elementXRef.current = e.clientX - transformX;
  };

  const handleDragEnd = () => {
    ref.current.style.transition = null;

    if (isDraggingRef.current) {
      isDraggingRef.current = false;
      setTimeout(() => {
        if (cardWidth / 4 < transformX + currentCard * cardWidth) {
          handlePrevCard();
        } else if (-cardWidth / 4 > transformX + currentCard * cardWidth) {
          handleNextCard();
        } else {
          handleUnchangeCard();
        }
      }, 30);
    }

    if (scrollBlocked) {
      allowScroll();
    }
  };

  const handleNextCard = (destination?: number) => {
    isNextAble
      ? setCurrentCard(current => {
          const next = destination ?? current + 1;
          handleTransform(next);
          return next;
        })
      : handleUnchangeCard();
  };

  const handlePrevCard = (destination?: number) => {
    isPrevAble
      ? setCurrentCard(current => {
          const prev = destination ?? current - 1;
          handleTransform(prev);
          return prev;
        })
      : handleUnchangeCard();
  };

  const handleUnchangeCard = () => {
    handleTransform(currentCard);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      // set default card to Business
      handleNextCard(1);

      let touchStartX;

      const handleTouch = throttle((e: TouchEvent) => {
        if (isDraggingRef.current) {
          if (Math.abs(touchStartX - e.touches[0].clientX) > 24) {
            blockScroll();
          }
          touchStartX = e.touches[0].clientX;

          setTransformX(e.changedTouches[0].clientX - elementXRef.current);
          ref.current.style.transform = `translate(${
            e.changedTouches[0].clientX - elementXRef.current
          }px, 0px)`;
        }
      }, 30);

      const handleDrag = throttle((e: MouseEvent) => {
        if (isDraggingRef.current) {
          setTransformX(e.clientX - elementXRef.current);
          ref.current.style.transform = `translate(${
            e.clientX - elementXRef.current
          }px, 0px)`;
        }
      }, 30);

      ref.current.addEventListener('touchmove', handleTouch);
      ref.current.addEventListener('mousemove', handleDrag);
      return () => {
        ref.current.removeEventListener('touchmove', handleTouch);
        ref.current.removeEventListener('mousemove', handleDrag);
      };
    }
  }, [ref.current]);

  return (
    <CardSlider {...props1}>
      <CardDragger
        ref={ref}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleDragEnd}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}>
        {CARD_LIST.map((cardContent, index: number) => (
          <Card
            key={cardContent.title}
            cardContent={cardContent}
            isCurrent={currentCard === index}
            index={index}
          />
        ))}
        {Array.from({ length: 2 }).map((_, index: number) => (
          <Card
            key={index}
            isCurrent={currentCard === index + CARD_LIST.length}
            index={index}
          />
        ))}
      </CardDragger>
    </CardSlider>
  );
};

export default CardList;
