import React from 'react';
import useHover from 'src/utils/useHover';

interface Props {
  link: string;
  icon: React.ReactNode;
  hovered: React.ReactNode;
}

const SNSIcon: React.FC<Props> = ({ link, icon, hovered }) => {
  const [ref, hover] = useHover<HTMLAnchorElement>();

  return (
    <a ref={ref} href={link} target="_blank" rel="noreferrer">
      {hover ? hovered : icon}
    </a>
  );
};

export default SNSIcon;
