import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React, { FC, HTMLProps } from 'react';
import MoreIcon from 'src/components/common/Icon/MoreIcon';
import useBreakpoint from 'src/hooks/useBreakPoint';
import useFadeIn from 'src/hooks/useFadeIn';
import tw from 'twin.macro';

const WorkWrapper = styled(motion.div)`
  ${tw`
    flex flex-row items-center justify-end
    pt-6 md:pt-8
  `}
`;

const WorkButton = tw.a`
  flex flex-row items-center
  space-x-1
  md:hover:opacity-40
`;

const WorkButtonMoreText = tw.p`
  text-xs md:text-base font-light
  text-color-gray09
`;

const HowWork: FC = () => {
  const anchorProps: HTMLProps<HTMLAnchorElement> = {
    href: 'https://career.hyperconnect.com/blog/',
    target: '_blank',
    rel: 'noreferrer',
  };

  const md = useBreakpoint('md');
  const props1 = useFadeIn();

  return (
    <WorkWrapper {...props1}>
      <WorkButton {...anchorProps}>
        <WorkButtonMoreText>
          하이퍼커넥터가 일하는 방식 더보기
        </WorkButtonMoreText>
        <MoreIcon size={md ? 24 : 20} />
      </WorkButton>
    </WorkWrapper>
  );
};

export default HowWork;
