import React, { Children, FC, useMemo, useState } from 'react';
import { Options, YouTubeProps } from 'react-youtube';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from '@emotion/styled';

import { YOUTUBE_VIDEOS } from 'src/constants/2022biz';
import useBreakpoint from 'src/hooks/useBreakPoint';
import { VideoContent } from 'src/types/2022biz';

import VideoModal from './VideoModal';
import VideoThumbnail from './VideoThumbnail';
import useFadeIn from 'src/hooks/useFadeIn';
import useWindowWidth from 'src/hooks/useWindowWidth';

const Article = styled(motion.article)`
  ${tw`
    grid grid-cols-2 md:grid-cols-4
    gap-4 md:gap-6
  `}
`;

const VIDEO_RATIO = 16 / 9;

const HyperPlayContent: FC = () => {
  const windowWidth = useWindowWidth();
  const md = useBreakpoint('md');

  const [currentVideoId, setCurrentVideoId] =
    useState<YouTubeProps['videoId']>(null);

  const props1 = useFadeIn();

  const onCloseModal = () => setCurrentVideoId(null);

  const { videoWrapperSize, youtubeVideos } = useMemo(() => {
    const videoListSize = md ? 1100 : windowWidth - 48;
    const columnSize = md ? 4 : 2;
    const gapSize = (md ? 24 : 16) * (columnSize - 1);
    const videoWidth = (videoListSize - gapSize) / columnSize;
    const youtubeVideoWidth = md ? 360 : Math.min(windowWidth - 128, 360);

    const videoWrapperSize = {
      width: videoWidth,
      height: videoWidth * VIDEO_RATIO,
    };

    const videoOptions: Options = {
      width: `${youtubeVideoWidth}px`,
      height: `${youtubeVideoWidth * VIDEO_RATIO}px`,
    };

    return {
      videoWrapperSize,
      youtubeVideos: YOUTUBE_VIDEOS.map(
        ({ video: { videoId }, ...content }): VideoContent => ({
          ...content,
          video: { videoId, opts: videoOptions },
        })
      ),
    };
  }, [windowWidth, md]);

  return (
    <Article {...props1}>
      {Children.toArray(
        youtubeVideos.map(({ video, ...videoContent }) => {
          const { videoId } = video;
          return (
            <>
              <VideoThumbnail
                onClickThumbnail={() => setCurrentVideoId(videoId)}
                videoId={videoId}
                {...videoContent}
                {...videoWrapperSize}
              />
              {videoId && (
                <VideoModal
                  isOpen={currentVideoId === videoId}
                  onClose={onCloseModal}
                  {...video}
                />
              )}
            </>
          );
        })
      )}
    </Article>
  );
};

export default HyperPlayContent;
