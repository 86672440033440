import React, { useRef, ReactNode, useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import throttle from 'lodash/throttle';
import { useInView } from 'react-intersection-observer';

import Section from 'src/components/common/Section';
import SectionTitle from 'src/components/common/Section/Title';

import useBreakpoint from 'src/hooks/useBreakPoint';

import ChevronLeft from 'src/images/specialPage/ic-chevron-left.svg';
import ChevronRight from 'src/images/specialPage/ic-chevron-right.svg';

const TITLE_SM = 'Voyage to the\nNext Level';
const TITLE_MD = 'Voyage to the Next Level';

const Wrapper = tw.section`
  relative
  w-full
  mb-[5.125rem] md:mb-[8.75rem]
`;

const CarouselBase = styled.div`
  ${tw`
    w-full max-w-[100vw] overflow-x-scroll
    flex flex-row items-stretch
    h-[75.56vw] md:h-[700px]
  `}

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CarouselItem = styled.div`
  ${tw`
    relative
    w-full min-w-[100vw]
  `}
  scroll-snap-align: center;
`;

const carouselBackgroundStyle = tw`
  absolute inset-0 object-cover z-[-1]
  after:content after:absolute after:inset-0 after:bg-black after:opacity-80
`;

const CarouselTextContainer = styled.div`
  ${tw`
    absolute top-[1.5rem] md:top-[3.5rem]
    w-full max-w-[1100px]
    px-[1.5rem] md:px-0
    grid grid-flow-row auto-rows-min
    gap-y-3
  `}
  left: 50%;
  transform: translateX(-50%);
`;

const CarouselTitle = tw.h3`
  font-poppins font-bold
  text-[1.25rem] md:text-[2rem]
  text-color-primary
`;

const CarouselDescription = tw.p`
  font-sans font-light
  text-sm md:text-[1.5rem]
  text-color-gray05
`;

const CarouselLink = tw.a`
  font-sans font-light
  text-[0.625rem] md:text-base
  text-color-gray09
  mt-1
`;

const carouselLinkIconStyle = tw`
  mb-[-2px] ml-1 w-[10px] md:w-[14.5px]
`;

const CarouselCounter = styled.p`
  ${tw`
    absolute bottom-[1.5rem] md:bottom-[2.5rem]
    ml-[-1.5rem] md:ml-0
    w-full max-w-[1100px]
    font-sans font-bold
    text-right
    text-white
    text-[12px] md:text-[22px]
  `}

  left: 50%;
  transform: translateX(-50%);
`;

const CarouselButton = tw.button`
  hidden
  outline-none
  absolute bottom-0
  w-[8rem]
  h-[75.56vw] md:h-[700px]
  md:flex flex-row items-center justify-center
`;

const CAROUSEL_ITEMS: {
  title: string;
  description: string;
  image(isMobile: boolean): ReactNode;
  link?: string;
  linkTitle?: ReactNode;
}[] = [
  {
    title: 'Global Social Discovery',
    description:
      '하이퍼커넥트는 혁신 기술을 통해 오프라인을 넘어 온라인으로 새로운 관계를 만드는 글로벌 소셜 디스커버리 시장을 리드하고 있습니다.',
    image(isMobile: boolean) {
      return isMobile ? (
        <StaticImage
          css={carouselBackgroundStyle}
          src="../../../../images/specialPage/biz-carousel-global-social-discovery-mobile.png"
          alt="Global Social Discovery"
        />
      ) : (
        <StaticImage
          css={carouselBackgroundStyle}
          src="../../../../images/specialPage/biz-carousel-global-social-discovery.jpg"
          alt="Global Social Discovery"
        />
      );
    },
  },
  {
    title: 'Localization Strategy',
    description:
      '각 지역의 문화적 특색을 반영한 현지화 전략으로 해외 매출 비중이 95% 이상을 차지, 하이퍼커넥트만의 뛰어난 글로벌 비즈니스 노하우를 보유하고 있습니다.',
    image(isMobile: boolean) {
      return isMobile ? (
        <StaticImage
          css={carouselBackgroundStyle}
          src="../../../../images/specialPage/biz-carousel-localization-strategy-mobile.png"
          alt="Localization Strategy"
        />
      ) : (
        <StaticImage
          css={carouselBackgroundStyle}
          src="../../../../images/specialPage/biz-carousel-localization-strategy.png"
          alt="Localization Strategy"
        />
      );
    },
  },
  {
    title: 'Broader Global Connection',
    description:
      '하이퍼커넥트는 나스닥 상장사 매치 그룹(Match Group)의 일원으로 매치 그룹 및 글로벌 브랜드들과 협업하며 더 넓은 세계로 나아가고 있습니다. ',
    image(isMobile: boolean) {
      return isMobile ? (
        <StaticImage
          css={carouselBackgroundStyle}
          src="../../../../images/specialPage/biz-carousel-broader-global-connection-mobile.png"
          alt="Broader Global Connection"
        />
      ) : (
        <StaticImage
          css={carouselBackgroundStyle}
          src="../../../../images/specialPage/biz-carousel-broader-global-connection.png"
          alt="Broader Global Connection"
        />
      );
    },
    linkTitle: (
      <span tw="flex flex-row items-center">
        매치 그룹 자세히 보기{' '}
        <img css={carouselLinkIconStyle} src="/icons/ic-chevron-right.svg" />
      </span>
    ),
    link: 'https://mtch.com',
  },
  {
    title: 'Hyper-Metarverse',
    description:
      '하이퍼커넥트는 메타버스를 활용한 새로운 소셜 디스커버리 영역을 개척하고 있습니다.',
    image(isMobile: boolean) {
      return isMobile ? (
        <StaticImage
          css={carouselBackgroundStyle}
          src="../../../../images/specialPage/biz-carousel-hyper-metarverse-mobile.png"
          alt="Hyper-Metarverse"
        />
      ) : (
        <StaticImage
          css={carouselBackgroundStyle}
          src="../../../../images/specialPage/biz-carousel-hyper-metarverse.jpg"
          alt="Hyper-Metarverse"
        />
      );
    },
    linkTitle: (
      <span tw="flex flex-row">
        하이퍼커넥트 자세히 보기{' '}
        <img css={carouselLinkIconStyle} src="/icons/ic-chevron-right.svg" />
      </span>
    ),
    link: 'https://www.hyperconnect.com/',
  },
];

function SpecialCarouselSection(): JSX.Element {
  const [viewRef, inView] = useInView();
  const [count, setCount] = useState(1);
  const mdUp = useBreakpoint('md');

  const containerRef = useRef<HTMLDivElement>();

  const getContainerWidth = () => {
    return containerRef.current?.getBoundingClientRect?.().width;
  };

  const getCount = () =>
    Math.round(
      containerRef.current?.scrollLeft /
        containerRef.current?.getBoundingClientRect?.().width
    ) + 1;

  const handlePrevious = () => {
    const containerWidth = getContainerWidth();

    if (count > 1) {
      containerRef.current?.scroll({
        behavior: 'smooth',
        left: containerRef?.current?.scrollLeft - containerWidth,
      });
      setCount(getCount() - 1);
    }
  };

  const handleNext = () => {
    const containerWidth = getContainerWidth();

    if (count < CAROUSEL_ITEMS.length) {
      containerRef.current?.scroll({
        behavior: 'smooth',
        left: containerRef?.current?.scrollLeft + containerWidth,
      });
      setCount(getCount() + 1);
    }
  };

  useEffect(() => {
    const containerNode = containerRef.current;

    if (containerNode) {
      let containerWidth = containerNode.getBoundingClientRect().width;

      const resizeListener = throttle(() => {
        containerWidth = containerNode.getBoundingClientRect().width;
      }, 500);

      const listener = throttle(() => {
        setCount(getCount());
      }, 1000 / 60);

      containerNode.addEventListener('scroll', listener);
      window.addEventListener('resize', resizeListener);

      let timer = window.setInterval(() => {
        if (inView) {
          handleNext();
        }
      }, 6000);

      return () => {
        containerNode.removeEventListener('scroll', listener);
        window.removeEventListener('resize', resizeListener);
        window.clearInterval(timer);
      };
    }
  }, [inView]);

  return (
    <Wrapper ref={viewRef}>
      <Section tw="pb-[40px] md:pb-[60px]">
        <SectionTitle tw="m-0 md:m-0" title={mdUp ? TITLE_MD : TITLE_SM} />
      </Section>
      <CarouselBase ref={containerRef}>
        {React.Children.toArray(
          CAROUSEL_ITEMS.map(
            ({ title, description, image, linkTitle, link }, index) => (
              <CarouselItem>
                {image(!mdUp)}
                <CarouselTextContainer>
                  <CarouselTitle>{title}</CarouselTitle>
                  <CarouselDescription>{description}</CarouselDescription>
                  {link && linkTitle && (
                    <CarouselLink href={link} target="_blank" rel="noreferrer">
                      {linkTitle}
                    </CarouselLink>
                  )}
                </CarouselTextContainer>
              </CarouselItem>
            )
          )
        )}
        <CarouselCounter>{`${count}/${CAROUSEL_ITEMS.length}`}</CarouselCounter>
      </CarouselBase>
      {count !== 1 && (
        <CarouselButton tw="left-0" onClick={handlePrevious}>
          <ChevronLeft tw="fill-[#ffffff] w-[2rem]" />
        </CarouselButton>
      )}
      {count < CAROUSEL_ITEMS.length && (
        <CarouselButton tw="right-0" onClick={handleNext}>
          <ChevronRight tw="fill-[#ffffff] w-[2rem]" />
        </CarouselButton>
      )}
    </Wrapper>
  );
}

export default SpecialCarouselSection;
