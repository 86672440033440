import React, { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import tw from 'twin.macro';

import SvgLogo from 'src/images/specialPage/logo.svg';

import { scrollTo } from 'src/utils/scroll';

const HeaderLogo = tw(SvgLogo)`
  w-[127.56px] md:w-[169.41px] h-full
  fill-[#ffffff]
  cursor-pointer
`;

const Header = tw.header`
  fixed
  top-0 left-0 right-0
  h-[60px]
  z-50
  flex flex-row items-center justify-center
  transition-all duration-300 ease-in-out
`;

const Section = tw.section`
  w-full md:max-w-[calc(1100px + 24px * 2)] h-full
  px-[24px]
  flex flex-row items-center
`;

const ApplyButton = tw.button`
  outline-none
  ml-auto
  font-poppins font-weight[700]
  text-[13px]
  px-[14px] md:px-[24px] py-[6px] md:py-[8px]
  border-[1px]
  text-white border-white
  hover:(text-color-gray01 bg-white)
  active:(text-color-gray01 bg-white)
  rounded-[18px]
`;

interface Props {
  className?: string;
}

function SpecialHeader({ className }: Props) {
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    const listener = throttle((event: Event) => {
      setScrolled(window.scrollY > 100);
    });

    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return (
    <Header
      className={className}
      css={scrolled && tw`bg-white/10 backdrop-blur-md`}>
      <Section>
        <button type="button" tw="outline-none" onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}>
          <HeaderLogo />
        </button>
        <ApplyButton type="button" onClick={() => scrollTo('jobs')}>채용공고보기</ApplyButton>
      </Section>
    </Header>
  );
}

export default SpecialHeader;
