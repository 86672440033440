import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

function useDateDiff(dateString: string): string[] {
  const [diff, setDiff] = useState<string[]>(['00', '00', '00', '00']);

  useEffect(() => {
    const timer = window.setInterval(() => {
      const date = dayjs(dateString);
      const now = dayjs();
      const diff = date.unix() - now.unix();

      if (diff <= 0) {
        return;
      }

      const days = Math.floor(diff / 60 / 60 / 24);

      const hours = Math.floor((diff - days * 60 * 60 * 24) / 60 / 60);

      const minutes = Math.floor(
        (diff - days * 60 * 60 * 24 - hours * 60 * 60) / 60
      );

      const seconds = Math.floor(
        diff - days * 60 * 60 * 24 - hours * 60 * 60 - minutes * 60
      );

      setDiff(
        [days, hours, minutes, seconds].map(value =>
          `${value}`.padStart(2, '0')
        )
      );
    }, 1000);

    return () => {
      window.clearInterval(timer);
    };
  }, [dateString]);

  return diff;
}

export default useDateDiff;
