export interface ImageSet {
  src?: string;
  srcSet?: string;
}

function split(path: string): string[] {
  const point = path.lastIndexOf('.');
  return point < 0
    ? [path]
    : [path.substr(0, point), path.substr(point + 1, path.length)];
}

export function getImageSet(imageUrl: string, srcSetOption = true): ImageSet {
  const [path, ext] = split(imageUrl);

  // return src and srcSet
  const src = imageUrl;
  const srcSet = srcSetOption
    ? `${path}@2x.${ext} 2x, ${path}@3x.${ext} 3x`
    : undefined;

  return { src, srcSet };
}
