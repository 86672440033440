import { Children, FC, PropsWithChildren } from 'react';
import tw from 'twin.macro';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import zip from 'lodash/zip';

import { END_DATE } from 'src/constants';
import useDateDiff from 'src/hooks/useDateDiff';

dayjs.extend(duration);

const UNIT_LABELS = ['days', 'hours', 'mins', 'secs'];

const NumberText: FC<PropsWithChildren<{ unit: string }>> = ({ unit, children }) => (
  <div tw="flex flex-col justify-center items-center">
    <h3 tw="font-poppins font-weight[400] text-[32px] md:text-[64px] text-color-gray09 mb-[12px] md:mb-[16px]">
      {children}
    </h3>
    <p tw="font-poppins font-weight[400] text-[16px] md:text-[38px] text-color-gray05">
      {unit}
    </p>
  </div>
);

const Divider = tw.span`
  font-poppins font-weight[400]
  text-[32px] md:text-[64px]
  text-color-gray05
  text-center
`;

const SpecialDDay: FC<{ className?: string }> = ({ className }) => {
  const diff = useDateDiff(END_DATE);

  const values = zip(UNIT_LABELS, diff)
    .reduce((a, b) => [...a, b, ':'], [])
    .slice(0, -1);

  return (
    <div tw="w-full grid grid-flow-col auto-cols-fr select-none" className={className}>
      {Children.toArray(
        values.map(value => {
          const [unit, amount] = value;
          return value === ':' ? (
            <Divider>{value}</Divider>
          ) : (
            <NumberText unit={unit}>{amount}</NumberText>
          );
        })
      )}
    </div>
  );
};

export default SpecialDDay;
