import styled from '@emotion/styled';
import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import tw from 'twin.macro';

import MoreIcon from 'src/components/common/Icon/MoreIcon';
import { CardContent } from 'src/types/2022biz';
import { getImageSet } from 'src/utils/image';
import { scrollTo } from 'src/utils/scroll';

import FlipCard from './FlipCard';
import useBreakpoint from 'src/hooks/useBreakPoint';

interface Props {
  cardContent?: CardContent;
  isCurrent: boolean;
  index?: number;
}

const CardWrapper = styled.div<{ isCurrent: boolean }>`
  ${tw`
    relative
    w-[250px] md:w-[500px]
    h-[150px] md:h-[300px]
    transition-[width,height]
    transition-duration[0.5s]
    font-poppins
    rounded-2xl
    opacity-80
  `}

  ${({ isCurrent }) =>
    isCurrent &&
    tw`
    w-[300px] h-[200px]
    md:w-[600px] md:h-[400px]
    transition-delay[0s]
    all-child:transition-delay[0.5s]
    opacity-100
  `}
`;

const FaceWrapper = tw.div`
  w-full h-full
  rounded-2xl
`;

const Image = tw.img`
  w-full h-full
  border-radius[inherit]
`;

const Overlay = tw.div`
  absolute top-0 left-0
  flex items-center justify-center
  w-full h-full
  border-radius[inherit]
  p-3.5 md:px-8 md:py-6
`;

const InactiveText = tw.p`
  text-2xl md:text-5xl
  text-color-gray03 font-bold
  select-none
`;

const CardContentWrapper = tw(Overlay)`
  flex-col items-start justify-between
`;

const FrontCardWrapper = styled(CardContentWrapper)<{ isCurrent?: boolean }>`
  ${tw`
    flex-col items-start justify-between
    opacity-40
  `}

  ${({ isCurrent }) => isCurrent && tw`opacity-100`}
`;

const FrontTitle = tw.div`
  font-bold text-left
`;

const FrontTitleText = tw.p`
  text-xl md:text-[3.5rem]
`;

const FrontDateText = tw.p`
  text-xs md:text-2xl
  mt-0.5 md:mt-0
`;

const FrontMore = tw.div`
  flex flex-row flex-none
  items-center justify-end
  w-full
`;

const FrontMoreText = tw.p`
  text-[0.625rem] md:text-xl
  font-bold
  font-sans
`;

const BackTextWrapper = tw.div`
  space-y-1 md:space-y-4
`;

const BackTitleText = tw.p`
  text-xl md:text-[3.5rem] font-bold
`;

const BackContent = tw.div`
  text-xs md:text-base leading-normal font-light
  font-sans
  whitespace-pre-line
`;

const BackSubtitleText = tw.p`
  font-bold
  mb-1.5
`;

const CardButton = tw.button`
  w-full
  text-sm md:text-xl font-bold
  text-center
  font-sans
  rounded-full
  text-color-gray01
  bg-white
  hover:bg-color-gray06
  py-2 md:py-5
`;

const Card: FC<Props> = ({ cardContent, isCurrent, index }) => {
  if (!cardContent) {
    return (
      <CardWrapper isCurrent={isCurrent}>
        <Image
          {...getImageSet(
            `/special/img-looking-for-card-${index % 2}-inactive.png`
          )}
        />
        <Overlay>
          <InactiveText>Coming Soon</InactiveText>
        </Overlay>
      </CardWrapper>
    );
  }

  const {
    title,
    subTitle,
    content,
    longContent,
    frontImageUrl,
    backImageUrl,
    frontStyle,
    backStyle,
    startAt,
    endAt,
    iconColor,
  } = cardContent;

  const md = useBreakpoint('md');

  const startDate = dayjs(startAt).format('M.DD');
  const endDate = dayjs(endAt).format('M.DD');

  const isActive = useMemo(() => {
    const nowDate = dayjs().format('M.DD');

    return startDate <= nowDate && nowDate <= endDate;
  }, []);

  // const scrollToJobs = () => scrollTo('jobs');

  const handleClick = () => {
    window.location.href = '/jobs';
  }

  return (
    <CardWrapper isCurrent={isCurrent}>
      <FlipCard
        frontFace={
          <FaceWrapper>
            <Image {...getImageSet(frontImageUrl)} />
            <FrontCardWrapper isCurrent={isCurrent} style={frontStyle}>
              <FrontTitle>
                <FrontTitleText>{title}</FrontTitleText>
                <FrontDateText>{`${startDate} - ${endDate}`}</FrontDateText>
              </FrontTitle>
              <FrontMore>
                <FrontMoreText>자세히 보기</FrontMoreText>
                <MoreIcon size={md ? 22 : 14} color={iconColor} />
              </FrontMore>
            </FrontCardWrapper>
          </FaceWrapper>
        }
        backFace={
          <FaceWrapper>
            <Image {...getImageSet(backImageUrl)} />
            <CardContentWrapper style={backStyle}>
              <BackTextWrapper>
                <BackTitleText>{`0${index + 1}.${title}`}</BackTitleText>
                <BackContent>
                  <BackSubtitleText>“{subTitle}”</BackSubtitleText>
                  {md && longContent ? longContent : content}
                </BackContent>
              </BackTextWrapper>
              {isActive && (
                <CardButton onClick={handleClick}>상시채용 더 알아보기</CardButton>
              )}
            </CardContentWrapper>
          </FaceWrapper>
        }
        disabled={!isCurrent}
      />
    </CardWrapper>
  );
};

export default Card;
