/**
 * Reference https://gist.github.com/ben-rogerson/b4b406dffcc18ae02f8a6c8c97bb58a8#gistcomment-3596530
 */

import { useLayoutEffect, useState } from 'react';
import { theme } from 'twin.macro';

const useBreakpoint = (breakpoint: string): boolean => {
  const [matches, setMatches] = useState<boolean>();

  useLayoutEffect(() => {
    // Create the query in useLayoutEffect since Next.js doesn't recognize 'matchMedia' during SSR
    const minWidth = theme('screens')[breakpoint];
    const mediaQueryList = matchMedia(`(min-width: ${minWidth})`);

    // Set the initial value
    setMatches(mediaQueryList.matches);

    // Listen for changes to the query match
    const onChange = (e: MediaQueryListEvent) => setMatches(e.matches);
    mediaQueryList.addEventListener('change', onChange);

    // Cleanup listener on unmount
    return () => mediaQueryList.removeEventListener('change', onChange);
  }, [breakpoint]);

  return matches;
};

export default useBreakpoint;
