import styled from '@emotion/styled';
import { FC } from 'react';
import tw from 'twin.macro';

import { VideoContent } from 'src/types/2022biz';
import { getImageSet } from 'src/utils/image';

interface Props extends Omit<VideoContent, 'video'> {
  width: number;
  height: number;
  onClickThumbnail: () => void;
  videoId: VideoContent['video']['videoId'];
}

const VideoWrapper = styled.div<{ width: number; height: number }>`
  ${tw`
    relative
    all-child:rounded-2xl
  `}

  ${({ width, height }) => `
    width: ${width}px;
    height: ${height}px;
  `}
`;

const ThumbnailImage = tw.img`
  w-full h-full
`;

const ThumbnailWrapper = tw.div`
  absolute inset-0
  w-full h-full
  space-y-1 md:space-y-2
  bg-transparent
  p-3.5 md:p-6
  z-20
`;

const ThumbnailTitle = tw.p`
  text-xs md:text-xl font-bold leading-[1.3]
  text-color-gray01
  whitespace-pre-line
`;

const ThumbnailNickname = tw.p`
  text-[0.625rem] leading-normal md:text-sm
  font-poppins font-extralight
  text-color-gray02
`;

const PlayIcon = tw.img`
  absolute
  left-3.5 bottom-3.5
  md:left-6 md:bottom-6
  w-7 h-7
  md:w-10 md:h-10
  z-20
`;

const Overlay = tw.div`
  absolute inset-0
  flex flex-col items-center justify-center
  w-full h-full
  text-xs md:text-2xl font-bold
  text-color-gray05
  bg-black opacity-50
  z-20
`;

const VideoThumbnail: FC<Props> = ({
  onClickThumbnail,
  thumbnailUrl,
  title,
  nickname,
  videoId,
  ...videoSize
}) => {
  return (
    <VideoWrapper onClick={onClickThumbnail} {...videoSize}>
      <ThumbnailImage {...getImageSet(thumbnailUrl)} />
      {!!videoId ? (
        <ThumbnailWrapper>
          <ThumbnailTitle>{title}</ThumbnailTitle>
          <ThumbnailNickname>{nickname}</ThumbnailNickname>
          <PlayIcon src="/icons/ic-play.svg" alt="youtube play" />
        </ThumbnailWrapper>
      ) : (
        <Overlay>coming soon</Overlay>
      )}
    </VideoWrapper>
  );
};

export default VideoThumbnail;
