import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React, { Children, FC } from 'react';
import { CAVEATS } from 'src/constants';
import useFadeIn from 'src/hooks/useFadeIn';
import tw from 'twin.macro';
import LogoList from '../LogoList';

const Base = tw.div`
  mt-10 md:mt-14
`;

const CaveatList = styled(motion.ul)`
  ${tw`
    mb-10 md:mb-[60px]
    pl-6 md:pl-9
    w-full
    list-disc
    grid grid-flow-row gap-y-[12px]
  `}
`;

const CaveatListItem = tw.li`
  font-sans font-light
  text-sm md:text-lg
  text-color-gray06
  whitespace-pre-wrap
`;

const Caveats: FC = () => {
  const props1 = useFadeIn();

  return (
    <Base>
      <CaveatList {...props1}>
        {Children.toArray(
          CAVEATS.map(text => <CaveatListItem>{text}</CaveatListItem>)
        )}
      </CaveatList>
      <LogoList />
    </Base>
  );
};

export default Caveats;
