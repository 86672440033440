import styled from '@emotion/styled';
import React, { Children, FC, useMemo } from 'react';
import parse, { DOMNode, HTMLReactParserOptions } from 'html-react-parser';
import { Element, Text } from 'domhandler/lib/node';
import tw from 'twin.macro';

import { APPLY_URL } from 'src/constants';
import { JD } from 'src/types';
import useFadeIn from 'src/hooks/useFadeIn';
import { motion } from 'framer-motion';

interface Props {
  node: JD;
  isOpen: boolean;
  onOpen: () => void;
}

const Base = tw(motion.li)`
  font-poppins
  text-left
  background-color[#4444444D]
  rounded-2xl
`;

const Item = styled.button<{ isOpen: boolean }>`
  ${tw`
    flex flex-col items-center
    w-full
    rounded-2xl
    p-5 md:p-6
  `}

  ${({ isOpen }) =>
    !isOpen &&
    tw`
    hover:bg-color-gray02
    active:bg-color-gray02
    active:all-child:opacity-70
  `}
`;

const Header = tw.div`
  flex justify-between
  w-full
`;

const Badge = tw.div`
  text-base text-color-primary font-bold
  bg-black bg-opacity-20
  rounded-[2.5rem]
  px-3 py-1
`;

const Icon = tw.img`
  w-6 h-6
`;

const SubHeader = tw(Header)`
  flex-col md:flex-row
  pt-3 md:pt-4
`;

const Title = styled.p<{ isActive: boolean }>`
  ${tw`
    text-lg font-bold
    md:text-xl
    text-left
  `}

  ${({ isActive }) => isActive && tw`text-color-primary`}
`;

const SubCategory = tw.div`
  flex
  space-x-3 md:space-x-6
  opacity-70
  pt-2 md:pt-0
`;

const CategoryItem = tw.div`
  w-[5.25rem] md:w-[7.5rem]
  text-base font-light
  text-left md:text-right
`;

const ContentWrapper = tw.div`
  space-y-8
  px-5 pb-10
  md:px-6 md:pb-16
`;

const Description = tw.div`
  flex flex-col
  text-sm md:text-lg
`;

const DescriptionTitle = tw.p`
  text-lg font-bold md:text-xl
  mt-8 mb-4
`;

const DescriptionContent = tw.p`
  text-sm md:text-lg
  opacity-70
`;

const SubDescription = tw(DescriptionContent)`
  md:text-base font-weight[300]
`;

const Content = tw.div``;

const ContentText = tw.p`
  text-lg font-bold
  mb-4
`;

const ContentList = styled.ul`
  ${tw`
    list-disc
    text-sm md:text-lg
    opacity-70
  `}

  & > li {
    ${tw`
      ml-6
      md:ml-8 md:mr-4
    `}
  }
`;

const ButtonWrapper = tw.div`
  px-5 pb-5
  md:px-8 md:pb-10
`;

const ApplyButton = tw.a`
  inline-block
  w-full
  text-xl md:text-2xl font-bold
  text-color-gray03 text-center
  rounded-full
  px-6 py-4 md:py-5
  active:all-child:opacity-70
  bg-color-gray02
  pointer-events-none
`;

const JobItem: FC<Props> = ({ node, isOpen, onOpen }) => {
  const props1 = useFadeIn();

  const iconSrc = useMemo(
    () => `/icons/ic-${isOpen ? 'minus' : 'plus'}.svg`,
    [isOpen]
  );

  if (!node) {
    return null;
  }

  const {
    categories: { team, location, commitment },
    text,
    description,
    lists,
  } = node;

  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      /**
       * check type of domNode with instanceof
       * @see https://github.com/remarkablemark/html-react-parser#ts-error-property-attribs-does-not-exist-on-type-domnode
       */
      if (domNode instanceof Text) {
        return <DescriptionContent>{domNode.data}</DescriptionContent>;
      }

      if (domNode instanceof Element) {
        const { tagName, firstChild } = domNode;
        if (firstChild instanceof Text) {
          switch (tagName.toLowerCase()) {
            case 'i':
              return <SubDescription>{firstChild.data}</SubDescription>;
            case 'span':
              return <DescriptionContent>{firstChild.data}</DescriptionContent>;
            case 'b':
              return <DescriptionTitle>{firstChild.data}</DescriptionTitle>;
            default:
              return;
          }
        } else if (tagName.toLowerCase() === 'br') {
          return <></>;
        }
      }
    },
  };

  return (
    <Base {...props1}>
      <Item isOpen={isOpen} onClick={onOpen}>
        <Header>
          <Badge>{team}</Badge>
          <Icon src={iconSrc} alt="job button" />
        </Header>
        <SubHeader>
          <Title isActive={isOpen}>{text}</Title>
          <SubCategory>
            <CategoryItem>{location}</CategoryItem>
            <CategoryItem>{commitment}</CategoryItem>
          </SubCategory>
        </SubHeader>
      </Item>
      {isOpen && (
        <>
          <ContentWrapper>
            <Description>{parse(description, options)}</Description>
            {Children.toArray(
              lists.map(({ text, content }) => (
                <Content>
                  <ContentText>{text}</ContentText>
                  <ContentList dangerouslySetInnerHTML={{ __html: content }} />
                </Content>
              ))
            )}
          </ContentWrapper>
          <ButtonWrapper>
            <ApplyButton href={APPLY_URL} target="_blank" rel="noreferrer">
              접수마감
            </ApplyButton>
          </ButtonWrapper>
        </>
      )}
    </Base>
  );
};

export default JobItem;
