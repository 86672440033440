import React, { FC, useState } from 'react';
import { ClassNames } from '@emotion/react';
import tw from 'twin.macro';
import Modal from 'react-modal';
import useBreakpoint from 'src/hooks/useBreakPoint';

const ModalBanner = tw.p`
  font-sans font-bold
  text-xs md:text-base
  text-color-primary
  bg-[#D4F7E9]
  rounded-full
  px-[24px] py-[11px]
`;

const ModalTitle = tw.h1`
  font-sans font-bold
  text-[1.375rem] md:text-2xl
  leading-8 md:leading-normal
  text-center
  whitespace-pre-line
  mt-4 mb-3
`;

const ModalDescription = tw.p`
  font-sans font-light
  text-center
  text-sm md:text-base
  leading-[24px]
  text-color-gray02
  whitespace-pre-line mb-8
`;

Modal.setAppElement('#___gatsby');

export interface SpecialPopupProps {
  opened?: boolean;
}

const SpecialPopup: FC<SpecialPopupProps> = ({ opened = false }) => {
  const [isOpen, setOpen] = useState(opened);

  const md = useBreakpoint('md');

  const closeModal = () => setOpen(false);

  return (
    <ClassNames>
      {({ css }) => {
        return (
          <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={500}
            shouldCloseOnEsc={false}
            overlayClassName={{
              base: css`
                ${tw`
                  fixed inset-0
                  z-[9999]
                  px-6 md:p-0
                  flex flex-col justify-center items-center
                  transition-opacity duration-500 ease-in-out
                `}

                background-color: rgba(34, 34, 34, 0.7);
              `,
              afterOpen: css`
                ${tw`opacity-100`}
              `,
              beforeClose: css`
                ${tw`opacity-0`}
              `,
            }}
            bodyOpenClassName={css`
              overflow: hidden;
            `}
            className={css`
              ${tw`
                    outline-none
                    bg-white
                    border-0 rounded-[24px] md:rounded-[32px]
                    w-full md:w-[446px]
                    max-w-[327px] md:max-w-[revert]
                    px-6 py-8
                `}

              background-image: url(/special/goodbye-popup.png);
              background-size: ${md ? '242px 242px' : '202px 202px'};
              background-repeat: no-repeat;
              background-position: ${md ? '242px 125px' : '178px 134px'};
            `}>
            <div tw="flex flex-col items-center">
              <ModalBanner>릴레이 채용 Business편</ModalBanner>
              <ModalTitle>{`서류접수가 마감되었습니다.`}</ModalTitle>
              <ModalDescription>
                {md ? (
                  <>
                    {`Special Benefit이 있는 릴레이 채용 접수는 마감되었지만 상시 채용으로 계속 모집 중입니다.`}
                    <br />
                    {`하이퍼커넥트 릴레이 채용에 관심을 가져주셔서 감사합니다.`}
                  </>
                ) : (
                  `상시 채용으로 계속 모집 중입니다.\n하이퍼커넥트 릴레이 채용에\n관심을 가져주셔서 감사합니다.`
                )}
              </ModalDescription>
              <button
                type="button"
                onClick={closeModal}
                css={tw`
                      w-full
                      bg-color-primary
                      font-sans font-bold text-base md:text-lg text-color-gray09
                      rounded-full
                      flex flex-row justify-center items-center
                      py-[18.5px] md:py-[17.5px]
                    `}>
                닫기
              </button>
            </div>
          </Modal>
        );
      }}
    </ClassNames>
  );
};

export default SpecialPopup;
