import { Children, FC } from 'react';
import tw from 'twin.macro';

import Section from 'src/components/common/Section';
import SectionTitle from 'src/components/common/Section/Title';
import { SPECIAL_BENEFIT_CONTENT_LIST } from 'src/constants/2022biz';
import { getImageSet } from 'src/utils/image';
import useFadeIn from 'src/hooks/useFadeIn';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const Body = tw.div`
  space-y-10
`;

const Article = styled(motion.article)`
  ${tw`
    flex flex-col items-center
    md:flex-row md:justify-between
    space-y-3 md:space-y-0 md:space-x-10
  `}
`;

const Image = tw.img`
  w-[186px] min-width[50%]
  md:w-[280px] md:min-width[unset]
`;

const ContentWrapper = tw.div`
  flex flex-col
  space-y-2 md:space-y-4
  text-left
  mt-3 md:mt-0
`;

const ContentTitle = tw.p`
  text-lg md:text-2xl font-bold
  text-color-gray09
`;

const Content = tw.p`
  text-sm md:text-lg font-light
  text-color-gray05
`;

const TITLE = 'Special Benefit';

const SUB_TITLE = '글로벌 커리어 여정을 지원하는 Special Benefit';

function SpecialBenefitArticle({
  imageUrl,
  title,
  content,
}: {
  imageUrl: string;
  title: string;
  content: string;
}) {
  const props1 = useFadeIn();

  return (
    <Article {...props1}>
      <Image {...getImageSet(imageUrl)} />
      <ContentWrapper>
        <ContentTitle>{title}</ContentTitle>
        <Content>{content}</Content>
      </ContentWrapper>
    </Article>
  );
}

const SpecialBenefit: FC = () => (
  <Section>
    <SectionTitle title={TITLE} subTitle={SUB_TITLE} />
    <Body>
      {Children.toArray(
        SPECIAL_BENEFIT_CONTENT_LIST.map(props => (
          <SpecialBenefitArticle {...props} />
        ))
      )}
    </Body>
  </Section>
);

export default SpecialBenefit;
