import { FC } from 'react';
import tw from 'twin.macro';

interface Props {
  size?: number;
  color?: string;
}

const SvgIcon = tw.svg`
  inline-block align-middle
`;

const MoreIcon: FC<Props> = ({ size = 24, color = 'white' }) => (
  <SvgIcon
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86358 19.6363C7.51211 19.2848 7.51211 18.715 7.86358 18.3635L14.2272 11.9999L7.86358 5.6363C7.51211 5.28483 7.51211 4.71498 7.86358 4.36351C8.21505 4.01203 8.7849 4.01203 9.13637 4.36351L16.1364 11.3635C16.4878 11.715 16.4878 12.2848 16.1364 12.6363L9.13637 19.6363C8.7849 19.9878 8.21505 19.9878 7.86358 19.6363Z"
      fill={color}
    />
  </SvgIcon>
);

export default MoreIcon;
