import React, { FC, useLayoutEffect, useRef } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import tw from 'twin.macro';

import useBreakpoint from 'src/hooks/useBreakPoint';
import CloseIcon from 'src/components/common/Icon/CloseIcon';
import styled from '@emotion/styled';

interface Props extends YouTubeProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalWrapper = styled.div<{ isOpen: boolean }>`
  ${tw`
    z-50
    fixed inset-0
    flex flex-col justify-center items-center
    w-screen h-screen
    transition-all duration-500 ease-in-out
  `}

  ${({ isOpen }) =>
    isOpen ? tw`visible opacity-100` : tw`invisible opacity-0`}
`;

const Overlay = tw.div`
  fixed inset-0
  w-full h-full
  bg-black bg-opacity-70
`;

const ModalBase = tw.div`
  flex flex-col items-end
  z-10
  rounded-2xl
  mx-16 md:mx-0
`;

const CloseButton = tw.button`
  w-6 h-6
  md:w-8 md:h-8
  mb-3 md:mb-2
`;

const YoutubeVideo = tw(YouTube)`
  rounded-2xl
`;

const VideoModal: FC<Props> = ({ isOpen, onClose, ...youtubeProps }) => {
  const md = useBreakpoint('md');

  const playerRef = useRef<YouTube>();

  useLayoutEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current.getInternalPlayer();

      if (isOpen) {
        player.playVideo();
      } else {
        player.pauseVideo();
      }
    }
  }, [isOpen]);

  return (
    <ModalWrapper isOpen={isOpen}>
      <Overlay onClick={onClose} />
      <ModalBase>
        <CloseButton onClick={onClose}>
          <CloseIcon size={md ? 32 : 24} />
        </CloseButton>
        <YoutubeVideo ref={playerRef} {...youtubeProps} />
      </ModalBase>
    </ModalWrapper>
  );
};

export default VideoModal;
