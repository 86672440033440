import { ComponentPropsWithoutRef, FC, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import useFadeIn from 'src/hooks/useFadeIn';

interface Props extends ComponentPropsWithoutRef<'div'> {
  title: string;
  subTitle?: string;
}

const Title = tw(motion.div)`
  font-poppins font-bold text-white
  text-4xl md:text-6xl
  whitespace-pre-line
  mb-10 md:mb-[3.75rem]
`;

const SubTitle = tw(motion.p)`
  font-sans
  text-lg md:text-4xl font-extralight
  text-color-gray09
  opacity-90
  mt-3
`;

const SectionTitle: FC<Props> = ({ className, title, subTitle }) => {
  const props1 = useFadeIn();

  return (
    <Title className={className} {...props1}>
      {title}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </Title>
  );
};

export default SectionTitle;
