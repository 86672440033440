import tw from 'twin.macro';

import { Faq } from 'src/types';
import {
  SpecialBenefitContent,
  HiringProcess,
  CardContent,
  VideoContent,
} from 'src/types/2022biz';

export const CARD_LIST: CardContent[] = [
  {
    title: 'PM',
    subTitle: '과거의 경험보다 현재의 열정을 봅니다',
    content:
      '뜨거운 열정과 리더십으로 글로벌 프로덕트를 이끌어갈 PM.\n하이퍼커넥트에는 정형화된 PM의 인재상이 없습니다.',
    frontImageUrl: '/special/img-looking-for-card-pm-front.png',
    backImageUrl: '/special/img-looking-for-card-pm-back.png',
    frontStyle: tw`text-color-gray09`,
    backStyle: tw`text-color-gray09`,
    startAt: '2022-01-17 00:00:00',
    endAt: '2022-02-14 23:59:59',
  },
  {
    title: 'Business',
    subTitle: '한국에서 가장 글로벌한 마케팅을 합니다!',
    content:
      '하이퍼커넥트 Business 조직은 글로벌을 무대로 새로운 시장을 만들어나가는 개척자입니다.\n하이퍼커넥트의 다음 항해지는 당신이 결정합니다!',
    longContent:
      '하이퍼커넥트의 Business 조직은 팀원 모두는 각 국가의 전문가이자, 새로운 시장을 만들어나가는 개척자입니다.\n전 세계 21개국 언어로 서비스를 제공하고 있는 하이퍼커넥트는 계속해서 새로운 시장을 개척하고 있습니다.\n하이퍼커넥트의 다음 항해지는 당신이 결정합니다!',
    frontImageUrl: '/special/img-looking-for-card-biz-front.png',
    backImageUrl: '/special/img-looking-for-card-biz-back.png',
    frontStyle: tw`text-color-gray01`,
    backStyle: tw`text-color-gray09`,
    startAt: '2022-06-13 00:00:00',
    endAt: '2022-07-11 23:59:59',
    iconColor: 'var(--color-gray01)',
  },
];

export const YOUTUBE_VIDEOS: VideoContent[] = [
  {
    title: '글로벌 혁신을 만드는\n즐거운 도전을 함께해요',
    nickname: 'Soony | Hakuna Live',
    thumbnailUrl: '/special/img-youtube-thumbnail-soony.png',
    video: { videoId: 'uU0-xt5tQu4' },
  },
  {
    title: '다양한 경험을 가진\n동료들과 함께해요',
    nickname: 'Tim | Hakuna Live',
    thumbnailUrl: '/special/img-youtube-thumbnail-tim.png',
    video: { videoId: '0jwlvrP6NSU' },
  },
  {
    title: '진짜 글로벌한 마케팅을\n할 수 있어요',
    nickname: 'Dan | Azar',
    thumbnailUrl: '/special/img-youtube-thumbnail-dan.png',
    video: { videoId: 'MfUgwb72EGM' },
  },
  {
    title: '경력과 관계없이\n실력으로 인정받아요',
    nickname: 'Benjamin | Hakuna Live',
    thumbnailUrl: '/special/img-youtube-thumbnail-benjamin.png',
    video: { videoId: '3fpAmmFXPqE' },
  },
];

export const FAQ_LIST: Faq[] = [
  {
    question: '모집 인원에 제한이 있나요?',
    answer:
      '인원수에 제한을 두지 않고 하이퍼커넥트와 핏이 잘 맞는 뛰어난 인재를 적극적으로 영입할 예정입니다.',
  },
  {
    question: '유관 경력이 있어야만 지원할 수 있나요?',
    answer:
      '유관 경력이 없더라도 각 포지션이 필요로 하는 역량이 있고, 해당 분야에 도전해보고 싶은 분들이라면 누구나 지원할 수 있습니다.',
  },
  {
    question: '해외에 거주 중인 경우에도 지원할 수 있나요? ',
    answer:
      '네, 가능합니다. 단, 최종 합격 시에는 서울에 소재한 오피스에서 근무하게 됩니다.',
  },
  {
    question: '재택/원격 근무가 가능한가요?',
    answer:
      '네, 가능합니다. 현재 하이퍼커넥트는 일하는 장소를 자유롭게 선택할 수 있는 원격근무제를 시행 중입니다. 신규입사자가 있을 경우에는 일정 기간 팀원이 출근해 초기 온보딩을 돕고 있습니다.',
  },
  {
    question: '인터뷰 전 참고해야 할 유의사항이 있나요?',
    answer:
      '원활한 인터뷰 진행을 위해 인재영입팀에서 화상 인터뷰 접속 가이드와 직무별 인터뷰 준비 가이드를 작성해 메일로 발송해드립니다. 해당 가이드에서 지원 포지션의 적합한 인재상과 인터뷰 전 유의사항 등을 확인하실 수 있습니다.',
  },
];

export const SPECIAL_BENEFIT_CONTENT_LIST: SpecialBenefitContent[] = [
  {
    imageUrl: '/special/img-special-benefit-global.png',
    title: '글로벌 역량 강화 지원금 지급',
    content:
      '릴레이 채용을 통해 입사할 경우 입사 시점에 500만 원의 글로벌 역량 지원금을 드립니다. 해외 현지 조사, 외국어 학습, 원서 구입 등 개인의 취향대로 자유롭게 사용할 수 있습니다.',
  },
  {
    imageUrl: '/special/img-special-benefit-reward.png',
    title: '추천인 보상금 x2',
    content:
      '하이퍼커넥트는 뛰어난 인재를 추천해주신 분들께 100만원의 추천 보상금을 지급하고 있습니다. 릴레이 채용 기간에는 기존 추천 보상금의 2배인 200만 원의 보상금을 드립니다.',
  },
  {
    imageUrl: '/special/img-special-benefit-landing.png',
    title: '맞춤형 소프트 랜딩 프로그램',
    content:
      '담당 업무 및 프로덕트에 대한 이해도를 높이고 하이퍼커넥트의 일하는 방식과 문화에 쉽게 적응할 수 있도록 맞춤형 소프트 랜딩 프로그램을 운영합니다. 입사 전 함께 일할 팀원 혹은 특정인과 티 타임 및 식사를 지원하고, 동호회 등 사내 각종 커뮤니케이션 활동을 선체험 할 수 있습니다. 이 밖에도 원활한 적응을 위해 필요로 하는 여러 활동들을 전폭적으로 지원해드립니다.',
  },
];

export const HIRING_PROCESS_LIST: HiringProcess[] = [
  {
    title: '서류 전형',
    content: '지원서를 토대로 지원자의 역량, 자질 등을 검토합니다.',
  },
  {
    title: '1차 면접 (60분)',
    content: '직무 지식과 역량, 문제 해결 능력을 중점으로 알아봅니다.',
  },
  {
    title: '2차 면접 (60분)',
    content:
      '지원자와 하이퍼커넥트의 컬쳐핏을 맞추어보고 업무 역량을 보다 깊이있게 알아봅니다.',
  },
  {
    title: '최종 합격',
    content: '처우, 입사일 등을 조율하고 협의합니다.',
  },
];
