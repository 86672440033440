import React, { useLayoutEffect, useRef } from 'react';

interface VideoSrc {
  src: any;
  videoType: string;
}
interface Props {
  srcSet: VideoSrc[];
  poster: string;
  muted?: boolean;
  videoType?: string;
  className?: string;
}

function AutoplayVideo({
  srcSet,
  poster,
  muted = true,
  className,
}: Props): JSX.Element {
  const videoRef = useRef<HTMLVideoElement>();

  useLayoutEffect(() => {
    const forcePlay = (event: Event) => {
      (event.target as HTMLVideoElement).play();
    };

    if (videoRef.current && srcSet) {
      videoRef.current.addEventListener('canplay', forcePlay);

      return () => {
        videoRef.current.removeEventListener('canplay', forcePlay);
      };
    }
  }, [videoRef.current, ...srcSet.map(({ src }) => src)]);

  return (
    <video
      muted={muted}
      autoPlay
      playsInline
      loop
      ref={videoRef}
      poster={poster}
      className={className}>
      {React.Children.toArray(
        srcSet.map(({ src, videoType }) => (
          <source src={src} type={videoType} />
        ))
      )}
      <img src={poster} alt="Fallback" className={className} />
    </video>
  );
}

export default AutoplayVideo;
