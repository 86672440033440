import { FC } from 'react';

import Section from 'src/components/common/Section';
import SectionTitle from 'src/components/common/Section/Title';

import HyperPlayContent from './HyperPlayContent';
import HowWork from './HowWork';

const TITLE = 'Hyper-Play';

const SUB_TITLE =
  '글로벌 소셜 디스커버리 시장을 개척하고 있는 하이퍼커넥터의 경험을 소개합니다!';

const HyperPlay: FC = () => (
  <Section>
    <SectionTitle title={TITLE} subTitle={SUB_TITLE} />
    <HyperPlayContent />
    <HowWork />
  </Section>
);

export default HyperPlay;
