import React from 'react';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import korean from 'dayjs/locale/ko';

import Mp4Background from 'src/videos/specialPage/biz-background-top.mp4';
import WebMBackground from 'src/videos/specialPage/biz-background-top.webm';
import AutoplayVideo from 'src/components/AutoplayVideo';
import { scrollTo } from 'src/utils/scroll';

const BackgroundVideo = styled(AutoplayVideo)`
  ${tw`
    absolute top-0 left-0 z-[-1]
    w-full h-full
    object-center object-cover
  `}
`;

import { END_DATE, START_DATE } from 'src/constants';
import useFadeIn from 'src/hooks/useFadeIn';

const Wrapper = tw.section`
  relative
  flex flex-col items-center justify-start
  w-screen h-screen md:min-h-[900px]
  pt-[8.75rem] md:pt-[8.5rem] pb-[7.625rem]
`;

const Description = tw(motion.p)`
  font-sans font-light
  text-white
  select-none
  text-[15px] md:text-[24px]
`;

const Title = tw(motion.h1)`
  font-poppins font-normal
  text-white
  text-[30px] md:text-[100px]
  text-center leading-[1.35]
  my-auto
  select-none
`;

const DateWrapper = tw(motion.div)`
  grid grid-flow-col gap-4 md:gap-8
  items-center
  px-[18vw] md:px-0
`;

const DateText = tw.p`
  select-none
  text-[14px] md:text-[24px]
  font-sans font-weight[700]
  text-white
`;

const ButtonWrapper = tw(motion.div)`
  mt-[2rem] md:mt-[3.5rem]
  md:px-[42px] md:py-[20px]
`;

const ApplyButton = tw.button`
  font-sans font-bold
  text-base md:text-[22px]
  bg-white text-black
  rounded-full
  flex
`;

const ApplyButtonInner = tw.div`
  w-full h-full
  px-[20px] md:px-[42px]
  py-[16px] md:py-[20px]
  rounded-full
  hover:(bg-black/[.15]) active:(bg-black/[.15])
`;

const GradientOverlay = styled.div`
  ${tw`
    absolute bottom-0 left-0 right-0
    h-[10.925vh] md:h-[13.47vh]
  `}

  background-image: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, #222222 100%);
`;

function SpecialTopSection(): JSX.Element {
  const props1 = useFadeIn();
  const props2 = useFadeIn();
  const props3 = useFadeIn();
  const props4 = useFadeIn();

  return (
    <Wrapper>
      <BackgroundVideo
        srcSet={[
          { src: Mp4Background, videoType: 'video/mp4' },
          { src: WebMBackground, videoType: 'video/webm' },
        ]}
        poster="/special/biz-background-top-poster.jpg"
      />
      <Description {...props1}>
        2022 하이퍼커넥트 릴레이 채용 Business편
      </Description>
      <Title>
      {/* <Title {...props2}> */}
        <em tw="font-bold not-italic text-[42px] md:text-[100px]">
          Voyage Across
        </em>
        <br />
        The Hyper-Metaverse
      </Title>
      <DateWrapper {...props3}>
        <DateText>
          {dayjs(START_DATE).locale(korean).format('M.DD (dd)')}
        </DateText>
        <img tw="w-[247.52px]" src="/icons/ic-wave.svg" alt="to" />
        <DateText>
          {dayjs(END_DATE).locale(korean).format('M.DD (dd)')}
        </DateText>
      </DateWrapper>
      <ButtonWrapper {...props4}>
        {/* <ApplyButton type="button" onClick={() => scrollTo('jobs')}>
          <ApplyButtonInner>상시채용 더 알아보기</ApplyButtonInner>
        </ApplyButton> */}
      </ButtonWrapper>
      <GradientOverlay />
    </Wrapper>
  );
}

export default SpecialTopSection;
