import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function useFadeIn() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start('visible');
    }
  }, [control, inView]);

  return {
    ref,
    initial: 'hidden',
    animate: control,
    variants: {
      visible: { opacity: 1, y: 0, transition: { duration: 0.7 } },
      hidden: { opacity: 0, y: 50 },
    },
  };
}

export default useFadeIn;
