import { FC, useState } from 'react';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

import { JD } from 'src/types';

import JobItem from './JobItem';

const Base = tw.ul`
  space-y-2 md:space-y-3
  list-none
`;

const JobList: FC = () => {
  const leverData = useStaticQuery<{
    allLever: {
      nodes: JD[];
    };
  }>(graphql`
    query {
      allLever(filter: { categories: { department: { regex: "/^2022/" } } }) {
        nodes {
          applyUrl
          categories {
            team
            location
            department
            commitment
          }
          description
          lever_id
          lists {
            content
            text
          }
          text
        }
      }
    }
  `);

  const [activeItem, setActiveItem] = useState('');
  const handleOpenItem = (leverId: string) => () => {
    setActiveItem(prev => (prev !== leverId ? leverId : ''));
  };

  return (
    <Base>
      {leverData.allLever.nodes.map(node => (
        <JobItem
          key={node.lever_id}
          node={node}
          isOpen={activeItem === node.lever_id}
          onOpen={handleOpenItem(node.lever_id)}
        />
      ))}
    </Base>
  );
};

export default JobList;
